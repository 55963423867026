<template>
  <div></div>
</template>

<script>
import storage from "../../common/utils/storage";
import { mapState } from 'vuex'

export default {
  name: 'JNJUser',
  data() {
    return {}
  },
  computed: {
    ...mapState(['Openid']),
    code() {
      return this.$route.query.code;
    },
  },
  created() {
    this.$toast.loading({
      message: '正在跳转中',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0
    });
    if (this.code) {
      this.$axios.post('/Api/Api/Web/GetJNJUserByWeb/GetUser?OpenID=' + this.Openid + '&code=' + this.code).then(res => {
        if (res.RetCode == '10000') {
          this.$toast.clear();
          storage.setStore('Token', res.Data.Token);
          let redirectUrl = sessionStorage.getItem("redirectUrl");
          if(redirectUrl) {
            this.$router.push(redirectUrl);
            sessionStorage.removeItem('redirectUrl');
          } else {
            this.$router.push('/');
          }
        } else {
          this.$toast(res.RetMsg);
        }
      })
    } else {
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      let maxPos = chars.length;
      let nonce = '';
      for (let i = 0; i < 6; i++) {
        nonce += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      window.location.href = `https://fedlogin.jnj.com/as/authorization.oauth2?client_id=${process.env.VUE_APP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_BASE_URL}/JNJUser&scope=address%20email%20openid%20phone%20profile&nonce=${nonce}`
    }
  }
}
</script>